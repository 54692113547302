import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";
import { auth, db } from "../Context/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import Notifications from "./shared/Notifications";

const navigation = {
  creator: [//editor
    { name: "Chat", href: "/chat" },
    { name: "Settings", href: "/editorSettings" },
    { name: "Find Editors", href: "/editors" },
    { name: "Portfolio", href: "/editorPortfolio" },
    { name: "Orders", href: "/orders" },
  ],
  editor: [//creator
    { name: "Chat", href: "/chat" },
    { name: "Editor Settings", href: "/editorSettings" },
    { name: "Find Creators", href: "/creators" },
    { name: "Editor Portfolio", href: "/editorPortfolio" },
    { name: "Orders", href: "/orders" },
  ],
  noAccount: [
    { name: "Signup", href: "/signup" },
    { name: "Login", href: "/login" },
  ],
};

const useUserData = (userId) => {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      const docRef = doc(db, "Users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [userId]);

  return userData;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TheNav() {
  const [open, setOpen] = useState(false);
  const currentUser = auth.currentUser;
  const userData = useUserData(currentUser?.uid);
  const userType = userData?.profileInfo?.role;
  const navigate = useNavigate();

  const links = userType?.toLowerCase() === "editor" ? navigation.editor : navigation.creator;

  const handleLogout = () => {
    if (currentUser) {
      auth.signOut();
      navigate("/");
    }

  };

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition show={open}>
        <Dialog className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <div className="mt-2">
                  <ul>
                    {links.map((item) => (
                      <li key={item.name} className="flow-root">
                        <Link
                          to={item.href}
                          className="block p-2 text-gray-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {currentUser ? (
                    <div className="flow-root">
                      <button
                        onClick={handleLogout}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        Logout
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="flow-root">
                        <Link
                          to="/login"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Sign In
                        </Link>
                      </div>
                      <div className="flow-root">
                        <Link
                          to="/signup"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Create account
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <header className="relative bg-white">
        <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Get 10% off your first order for a successful referral (purchase)
        </p>
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              <button
                type="button"
                className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link to="/">
                  <span className="sr-only">Your Company</span>
                  <img className="h-20 w-auto" src={Logo} alt="" />
                </Link>
              </div>

              {/* Desktop Menu */}
              <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                {links.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-sm font-medium text-gray-700 hover:text-gray-800"
                  >
                    {item.name}
                  </Link>
                ))}
                <span className="h-6 w-px bg-gray-200" aria-hidden="true" />


                {currentUser ? (
                  <>
                    <Notifications />
                    <button
                      onClick={handleLogout}
                      className="text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      Sign In
                    </Link>
                    <Link
                      to="/signup"
                      className="text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      Create Account
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
