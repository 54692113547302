import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
const OrderRow = React.memo(({ order, onClick }) => (
  <tr className="border-b hover:bg-gray-50">
    <td className="px-4 py-2 cursor-pointer" onClick={onClick}>
      {order.orderNumber}
    </td>
    <td className="px-4 py-2">{order.title}</td>
    <td className="px-4 py-2">{order.description}</td>
    <td className="px-4 py-2">{order.dueDate}</td>
    <td className="px-4 py-2">{order.daysNeeded}</td>
    {/* <td className="px-4 py-2">${order.price.final.toFixed(2)}</td> */}
    <td className="px-4 py-2">
      <span
        className={`px-2 py-1 rounded-full text-xs font-semibold
        ${order.status === "pending"
            ? "bg-yellow-200 text-yellow-800"
            : order.status === "completed"
              ? "bg-green-200 text-green-800"
              : "bg-gray-200 text-gray-800"
          }`}
      >
        {order.status}
      </span>
    </td>
  </tr>
));
const EditorOrders = ({ orders }) => {
  const navigate = useNavigate();
  const flattenedOrders = useMemo(() => {
    return Object.values(orders).flatMap((statusOrders) =>
      Object.values(statusOrders)
    );
  }, [orders]);

  return (
    <div className="flex flex-col w-full text-center mt-5 gap-y-5">
      <h1 className="text-2xl font-bold mb-4 text-left text-center">Creator's Order List</h1>
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2">Order Number</th>
              <th className="px-4 py-2">Title</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Due Date</th>
              <th className="px-4 py-2">Days Needed</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {flattenedOrders.map((order) => (
              <OrderRow
                key={order.orderNumber}
                order={order}
                onClick={() => navigate(`/order/${order.orderNumber}`)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(EditorOrders);
