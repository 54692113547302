import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs, limit, startAfter } from 'firebase/firestore';
import { db } from '../Context/firebaseConfig';
import TheNav from '../Components/navbar';
import { uniqBy } from 'lodash';

export default function ViewEditors() {
    const [editors, setEditors] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const LIMIT = 8;

    const fetchEditors = async () => {
        setLoading(true);
        let q = query(collection(db, "Users"), where("profileInfo.role", "==", "Editor"));
        if (lastDoc) {
            q = query(q, startAfter(lastDoc));
        }

        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const fetchedEditors = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Use lodash's uniqBy to ensure editors array has only unique items by 'id'
                setEditors(prev => uniqBy([...prev, ...fetchedEditors], 'id'));
                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

                if (querySnapshot.docs.length < LIMIT) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Failed to fetch editors:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 && !loading && hasMore) {
            fetchEditors();
        }
    }, [loading, hasMore]);

    useEffect(() => {
        fetchEditors();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <>
            <TheNav />
            <p className="text-4xl text-gray-900 font-bold dark:text-white" style={{ textAlign: "center", marginTop: "30px" }}>Available Editors</p>
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
                        {editors.map((editor) => (
                            <Link
                                to={`/editor/${editor.profileInfo.username}`}
                                key={editor.id}
                                className="group"
                            >
                                <div className="flex flex-col items-center text-center">
                                    <div className="rounded-full overflow-hidden">
                                        <img
                                            src={editor.profileInfo.profilePicUrl}
                                            className="rounded-full w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                                            alt={`${editor.profileInfo.username}'s profile`}
                                        />
                                    </div>
                                    <h3 className="mt-4 text-lg md:text-xl font-bold text-gray-700">
                                        @{editor.profileInfo.username}
                                    </h3>
                                    <div className="mt-2">
                                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                            {editor.profileInfo.mainGame}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>

                    {loading && <p className="text-center text-gray-500 mt-5">Loading...</p>}
                </div>
            </div>
        </>
    );
}
